import React from 'react'
import './Test.css'

export const Test = () => {
  return (
    <div>
      <div className="first">
        <section>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nullam nec
        </section>
      </div>
      <div className='second'>
        {/* Two sections here */}
        <section>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nullam nec
        </section>
        <section>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nullam nec
        </section>
      </div>
    </div>
  )
}
