import React from 'react';

const Works = () => {

  return (

    <div>
      Works
    </div>
  )
}

export default Works;